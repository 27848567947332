<template>
  <b-nav-item-dropdown
    right
    toggle-class="d-flex align-items-center dropdown-user-link"
    class="dropdown-user"
  >
    <template #button-content>
      <b-badge
        v-if="isUserImpersonated"
        variant="danger"
        class="text-uppercase mr-2"
      >
        Impersonated
      </b-badge>
      <div class="d-sm-flex d-none user-nav">
        <p class="user-name font-weight-bolder mb-0">
          {{ userProfile.name }} {{ userProfile.surname }}
        </p>
        <span class="user-status">{{ userProfile.email }}</span>
      </div>
      <b-avatar
        size="40"
        variant="light-primary"
        badge
        :src="userProfile.profileImageUrl"
        class="badge-minimal"
        badge-variant="success"
      />
    </template>

    <b-dropdown-item
      link-class="d-flex align-items-center"
      :to="{ name: 'account-setting' }"
    >
      <feather-icon
        size="16"
        icon="UserIcon"
        class="mr-50"
      />
      <span>Profile</span>
    </b-dropdown-item>

    <b-dropdown-divider />

    <b-dropdown-item
      link-class="d-flex align-items-center"
      :to="{ name: 'faq' }"
    >
      <feather-icon
        size="16"
        icon="HelpCircleIcon"
        class="mr-50"
      />
      <span>FAQ</span>
    </b-dropdown-item>

    <b-dropdown-divider />

    <b-dropdown-item
      link-class="d-flex align-items-center"
      @click="logout"
    >
      <feather-icon
        size="16"
        icon="LogOutIcon"
        class="mr-50"
      />
      <span>Logout</span>
    </b-dropdown-item>

    <b-dropdown-item
      v-if="isUserImpersonated"
      link-class="d-flex align-items-center bg-danger text-light"
      @click="cancelImpersonation"
    >
      <span>Stop Impersonation</span>
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>

import { mapGetters } from 'vuex'
import {
  BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar, BBadge,
} from 'bootstrap-vue'

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    BBadge,
  },
  computed: {
    ...mapGetters({
      userProfile: 'auth/userProfile',
      isUserImpersonated: 'auth/isUserImpersonated',
    }),
  },
  methods: {
    logout() {
      this.$store.dispatch('auth/signOut')

      // Reset ability
      // this.$ability.update(initialAbility)

      // Redirect to login page
      this.$router.push({ name: 'auth-login' })
    },
    cancelImpersonation() {
      this.$store.dispatch('auth/cancelImpersonate')
    },
  },
}
</script>
