<template>
  <b-nav-item-dropdown
    v-if="isSystemAdmin"
    right
    toggle-class="d-flex align-items-center"
  >
    <template #button-content>
      <feather-icon
        size="21"
        icon="ToolIcon"
      />
    </template>

    <b-dropdown-item
      link-class="d-flex align-items-center"
      @click="triggerCrmSync"
    >
      <span>Trigger CRM Sync</span>
    </b-dropdown-item>

    <b-dropdown-item
      link-class="d-flex align-items-center"
      @click="triggerOldPortalUserSync"
    >
      <span>Trigger old Portal user sync</span>
    </b-dropdown-item>

    <b-dropdown-item
      link-class="d-flex align-items-center"
      @click="triggerOldPortalSystemCheckups"
    >
      <span>Trigger old Portal System Checkups</span>
    </b-dropdown-item>

    <b-dropdown-item
      link-class="d-flex align-items-center"
      @click="triggerAllAssetToAsupMatcher"
    >
      <span>Trigger Asset to ASUP matcher (All assets)</span>
    </b-dropdown-item>

    <b-dropdown-item
      link-class="d-flex align-items-center"
      @click="triggerSingleAssetToAsupMatcher"
    >
      <span>Trigger Asset to ASUP matcher (Single assets)...</span>
    </b-dropdown-item>

    <b-dropdown-item
      link-class="d-flex align-items-center"
      @click="triggerDataDomainBuild(false)"
    >
      <span>Build DataDomain (Delta)</span>
    </b-dropdown-item>

    <b-dropdown-item
      link-class="d-flex align-items-center"
      @click="triggerDataDomainBuild(true)"
    >
      <span>Build DataDomain</span>
    </b-dropdown-item>

    <b-dropdown-item
      link-class="d-flex align-items-center"
      @click="triggerNetWorkerBuild(false)"
    >
      <span>Build NetWorker (Delta)</span>
    </b-dropdown-item>

    <b-dropdown-item
      link-class="d-flex align-items-center"
      @click="triggerNetWorkerBuild(true)"
    >
      <span>Build NetWorker</span>
    </b-dropdown-item>

    <b-dropdown-item
      link-class="d-flex align-items-center"
      @click="triggerAvamarBuild(false)"
    >
      <span>Build Avamar (Delta)</span>
    </b-dropdown-item>

    <b-dropdown-item
      link-class="d-flex align-items-center"
      @click="triggerAvamarBuild(true)"
    >
      <span>Build Avamar</span>
    </b-dropdown-item>

    <b-dropdown-item
      link-class="d-flex align-items-center"
      @click="triggerVeeamBuild(false)"
    >
      <span>Build Veeam (Delta)</span>
    </b-dropdown-item>

    <b-dropdown-item
      link-class="d-flex align-items-center"
      @click="triggerVeeamBuild(true)"
    >
      <span>Build Veeam</span>
    </b-dropdown-item>

    <b-dropdown-item
      link-class="d-flex align-items-center"
      @click="triggerPpdmBuild(false)"
    >
      <span>Build PPDM (Delta)</span>
    </b-dropdown-item>

    <b-dropdown-item
      link-class="d-flex align-items-center"
      @click="triggerPpdmBuild(true)"
    >
      <span>Build PPDM</span>
    </b-dropdown-item>

    <b-dropdown-item
      link-class="d-flex align-items-center"
      @click="triggerGenericBuild(false)"
    >
      <span>Build Generic (Delta)</span>
    </b-dropdown-item>

    <b-dropdown-item
      link-class="d-flex align-items-center"
      @click="triggerGenericBuild(true)"
    >
      <span>Build Generic</span>
    </b-dropdown-item>

    <b-dropdown-item
      link-class="d-flex align-items-center"
      @click="triggerAgentV1Build(false)"
    >
      <span>Build Agents V1 (Delta)</span>
    </b-dropdown-item>

    <b-dropdown-item
      link-class="d-flex align-items-center"
      @click="triggerAgentV1Build(true)"
    >
      <span>Build Agents V1</span>
    </b-dropdown-item>

    <b-dropdown-item
      link-class="d-flex align-items-center"
      @click="triggerAgentV2Build(false)"
    >
      <span>Build Agents V2 (Delta)</span>
    </b-dropdown-item>

    <b-dropdown-item
      link-class="d-flex align-items-center"
      @click="triggerAgentV2Build(true)"
    >
      <span>Build Agents V2</span>
    </b-dropdown-item>

  </b-nav-item-dropdown>
</template>

<script>

import { mapGetters } from 'vuex'
import {
  BNavItemDropdown, BDropdownItem,
} from 'bootstrap-vue'

import AssetService from '@/service/asset.service'
import UserService from '@/service/user.service'
import CrmService from '@/service/crm.service'
import DataDomainService from '@/service/datadomain.service'
import NetWorkerService from '@/service/networker.service'
import AvamarService from '@/service/avamar.service'
import VeeamService from '@/service/veeam.service'
import PpdmService from '@/service/ppdm.service'
import GenericService from '@/service/generic.service'
import DailycheckupService from '@/service/dailycheckup.service'
import AgentV1Service from '@/service/agent-v1.service'
import AgentV2Service from '@/service/agent-v2.service'

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
  },
  computed: {
    ...mapGetters({
      isSystemAdmin: 'auth/isSystemAdmin',
    }),
  },
  methods: {
    triggerCrmSync() {
      CrmService.triggerSynchronization()
        .then(result => {
          this.showTriggerConfirmation(result)
        })
    },
    triggerOldPortalUserSync() {
      UserService.triggerOldPortalUserSynchronization()
        .then(result => {
          this.showTriggerConfirmation(result)
        })
    },
    triggerAllAssetToAsupMatcher() {
      AssetService.triggerAllAssetAsupMatcher()
        .then(result => {
          this.showTriggerConfirmation(result)
        })
    },
    triggerAgentV1Build(_force) {
      const force = _force || false

      AgentV1Service.triggerBuildAllAsync({ force })
        .then(result => {
          this.showTriggerConfirmation(result)
        })
    },
    triggerAgentV2Build(_force) {
      const force = _force || false

      AgentV2Service.triggerBuildAllAsync({ force })
        .then(result => {
          this.showTriggerConfirmation(result)
        })
    },
    triggerDataDomainBuild(_force) {
      const force = _force || false

      DataDomainService.triggerBuildAllAsync({ force })
        .then(result => {
          this.showTriggerConfirmation(result)
        })
    },
    triggerNetWorkerBuild(_force) {
      const force = _force || false

      NetWorkerService.triggerBuildAllAsync({ force })
        .then(result => {
          this.showTriggerConfirmation(result)
        })
    },
    triggerAvamarBuild(_force) {
      const force = _force || false

      AvamarService.triggerBuildAllAsync({ force })
        .then(result => {
          this.showTriggerConfirmation(result)
        })
    },
    triggerVeeamBuild(_force) {
      const force = _force || false

      VeeamService.triggerBuildAllAsync({ force })
        .then(result => {
          this.showTriggerConfirmation(result)
        })
    },
    triggerPpdmBuild(_force) {
      const force = _force || false

      PpdmService.triggerBuildAllAsync({ force })
        .then(result => {
          this.showTriggerConfirmation(result)
        })
    },
    triggerGenericBuild(_force) {
      const force = _force || false

      GenericService.triggerBuildAllAsync({ force })
        .then(result => {
          this.showTriggerConfirmation(result)
        })
    },
    triggerOldPortalSystemCheckups() {
      DailycheckupService.triggerSynchronization()
        .then(result => {
          this.showTriggerConfirmation(result)
        })
    },
    triggerSingleAssetToAsupMatcher() {
      const self = this

      self.$swal({
        title: 'Insert Asset Id',
        icon: 'info',
        input: 'text',
        inputAttributes: {
          style: 'margin-top: 10px',
        },
        inputPlaceholder: 'Enter asset id',
        confirmButtonText: 'Trigger',
        showLoaderOnConfirm: true,
        preConfirm: assetId => AssetService.triggerSingleAssetAsupMatcher(assetId)
          .catch(() => {
            self.$swal.showValidationMessage(
              'Failed to validate asset id.',
            )
          }),
        allowOutsideClick: () => !self.$swal.isLoading(),
      }).then(clickResult => {
        if (clickResult.isConfirmed) {
          self.showTriggerConfirmation()
        }
      })
    },

    showTriggerConfirmation(jobId) {
      this.$swal.fire({
        title: 'Triggered',
        text: `Job Id: ${jobId}`,
        icon: 'success',
      })
    },
  },
}
</script>
