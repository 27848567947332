<template>
  <layout-vertical>

    <router-view />

    <template #vertical-menu-header="{ toggleVerticalMenuActive, toggleCollapsed, collapseTogglerIcon}">
      <AppHeader
        :toggle-vertical-menu-active="toggleVerticalMenuActive"
        :toggle-collapsed="toggleCollapsed"
        :collapse-toggler-icon="collapseTogglerIcon"
      />
    </template>

    <template #navbar="{ toggleVerticalMenuActive }">
      <navbar :toggle-vertical-menu-active="toggleVerticalMenuActive" />
    </template>

    <template v-slot:footer>
      <AppFooter />
    </template>

    <template v-slot:breadcrumb>
      <AppBreadcrump />
    </template>

    <!-- <app-customizer
      v-if="showCustomizer"
      slot="customizer"
    /> -->
  </layout-vertical>
</template>

<script>
import LayoutVertical from '@core/layouts/layout-vertical/LayoutVertical.vue'
// import AppCustomizer from '@core/layouts/components/app-customizer/AppCustomizer.vue'
// import { $themeConfig } from '@themeConfig'
import Navbar from '../components/Navbar.vue'
import AppFooter from '../components/AppFooter.vue'
import AppHeader from './components/AppHeader.vue'
import AppBreadcrump from '../components/AppBreadcrumb.vue'

export default {
  components: {
    // AppCustomizer,
    LayoutVertical,
    Navbar,
    AppFooter,
    AppHeader,
    AppBreadcrump,
  },
  data() {
    return {
      // showCustomizer: $themeConfig.layout.customizer,
    }
  },
}
</script>
