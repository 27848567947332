<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
      <dark-Toggler class="d-none d-lg-block" />
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <!--<OldPortalLink />-->
      <!--<Locales />-->
      <LinkLibrary />
      <AdminTools />
      <!--<SearchBar />-->
      <!--<Settings />-->
      <UserDropdown />

    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink, BNavbarNav,
} from 'bootstrap-vue'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
// import Locales from './components/locales.vue'
import UserDropdown from './components/user-dropdown.vue'
// import Settings from './components/settings.vue'
// import SearchBar from './components/search-bar.vue'
import AdminTools from './components/admintools.vue'
import LinkLibrary from './components/link-library.vue'
// import OldPortalLink from './components/old-portal-link.vue'

export default {
  components: {
    BLink,
    BNavbarNav,
    // Navbar Components
    DarkToggler,
    // Locales,
    UserDropdown,
    // Settings,
    // SearchBar,
    AdminTools,
    LinkLibrary,
    // OldPortalLink,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
}
</script>
