/*
import store from '@/store'
import host from './host'
import tenant from './tenant'
*/
// Array of sections
// export default [...host]
/*
function getNavItems() {
  if (store.getters['auth/isHost'] === true) {
    return [...host]
  }

  return [...tenant]
}

export default getNavItems()
*/
export default [
  /* {
    title: 'Dashboards',
    icon: 'HomeIcon',
    children: [
      {
        title: 'Dashboard 1',
        icon: 'BoxIcon',
        action: '__host__',
      },
      {
        title: 'Dashboard 2',
        icon: 'BoxIcon',
        action: '__host__',
      },
      {
        title: 'Dashboard 3',
        icon: 'BoxIcon',
        action: '__host__',
      },
      {
        title: 'Dashboard 4',
        icon: 'BoxIcon',
        action: '__host__',
      },
    ],
  }, */

  {
    title: 'Dashboards',
    icon: 'PieChartIcon',
    children: [
      {
        title: 'ProActive Checkups',
        route: 'dashboard-proActiveCheckups',
        action: 'Core.SystemCheckups',
      },
    ],
  },
  {
    title: 'Reports',
    icon: 'FileTextIcon',
    children: [
      {
        title: 'Email Report',
        route: 'asupReport',
        action: 'Core.Reporting.AsupReport',
      },
      {
        title: 'RMS Report',
        route: 'rmsReport',
        action: '__host__Core.Reporting.Rms',
      },
    ],
  },
  {
    title: 'Downloads',
    icon: 'DownloadIcon',
    route: 'downloads',
    action: '',
  },
  {
    header: 'Systems',
    action: '',
  },
  {
    title: 'Asset',
    route: 'asset-list',
    icon: 'BoxIcon',
    action: 'Core.Assets',
  },
  /* {
    title: 'ProActive Service',
    icon: 'StarIcon',
    action: '__host__Core.SystemCheckups',
  }, */
  {
    title: 'Autosupport',
    icon: 'HomeIcon',
    action: 'Autosupport',
    children: [
      {
        title: 'DataDomain',
        route: 'datadomain-list',
        icon: 'BoxIcon',
        action: 'Autosupport.DataDomain',
      },
      {
        title: 'ONE Backup',
        route: 'datadomain-apex-list',
        icon: 'BoxIcon',
        action: 'Autosupport.DataDomain.Apex',
      },
      {
        title: 'NetWorker',
        route: 'networker-list',
        icon: 'BoxIcon',
        action: 'Autosupport.NetWorker',
      },
      {
        title: 'Avamar',
        route: 'avamar-list',
        icon: 'BoxIcon',
        action: 'Autosupport.Avamar',
      },
      {
        title: 'PPDM',
        route: 'ppdm-list',
        icon: 'BoxIcon',
        action: 'Autosupport.Ppdm',
      },
      {
        title: 'Veeam',
        route: 'veeam-list',
        icon: 'BoxIcon',
        action: 'Autosupport.Veeam',
      },
      {
        title: 'Generic',
        route: 'generic-list',
        icon: 'BoxIcon',
        action: 'Autosupport.Generic',
      },
      {
        title: 'Agent',
        route: 'agent-list',
        icon: 'BoxIcon',
        action: 'Autosupport.Agent',
      },
    ],
  },
  {
    title: 'Tickets',
    icon: 'LifeBuoyIcon',
    route: 'incident-list',
    action: 'Core.Incidents',
  },
  {
    header: 'Manage',
    action: '',
  },
  {
    title: 'Host',
    route: 'host:manage:host-detail',
    icon: 'HomeIcon',
    action: '__role__systemadmin',
  },
  {
    title: 'Messages',
    route: 'host:manage:message',
    icon: 'MailIcon',
    action: '__host__Message.Messages',
  },
  {
    title: 'Tenants',
    route: 'host:manage:tenant-list',
    icon: 'HomeIcon',
    action: '__host__Core.Tenants',
  },
  {
    title: 'Users',
    route: 'host:manage:user-list',
    icon: 'UsersIcon',
    action: '__host__Core.Users',
  },
  {
    title: 'Monitor',
    route: 'monitor-app',
    icon: 'BellIcon',
    action: 'Watchdog.Monitors',
  },
  /* {
    title: 'Settings',
    icon: 'SettingsIcon',
    action: '__host__',
  }, */
  {
    title: 'Organization',
    route: 'tenant:manage:tenant-detail',
    icon: 'HomeIcon',
    // action: '__tenant__Core.Incidents',
    action: '__tenant__ManageOrganization',
  },
  {
    title: 'Reference lists',
    icon: 'ListIcon',
    action: '__special_manageReferenceList',
    children: [
      {
        title: 'Avamar',
        route: 'reference-list.avamar',
        icon: 'BoxIcon',
        action: '__special_manageReferenceList',
      },
    ],
  },
  /* {
    header: 'Misc.',
    action: '',
  },
  {
    title: 'CRM',
    icon: 'HomeIcon',
    action: '__host__',
    children: [
      {
        title: 'Account',
        icon: 'BoxIcon',
        action: '__host__',
      },
      {
        title: 'Contact',
        icon: 'BoxIcon',
        action: '__host__',
      },
      {
        title: 'Project',
        icon: 'BoxIcon',
        action: '__host__',
      },
      {
        title: 'Invoice',
        icon: 'BoxIcon',
        action: '__host__',
      },
    ],
  }, */
]
