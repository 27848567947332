<template>
  <b-nav-item-dropdown
    v-if="isCurrentUserHost"
    right
    menu-class="dropdown-menu-media"
    toggle-class="d-flex align-items-center"
  >
    <template #button-content>
      <feather-icon
        size="21"
        icon="LinkIcon"
      />
    </template>

    <!-- Header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">
          Links
        </h4>
      </div>
    </li>

    <ul class="mt-2 mb-2">
      <li>
        <a
          href="https://www.office.com/"
          target="_blank"
        >
          M365
        </a>
      </li>
      <li>
        <a
          href="https://mentiq.zammad.com/"
          target="_blank"
        >
          Zammad
        </a>
      </li>
      <li>
        <a
          href="https://support.orchestra.de/"
          target="_blank"
        >
          TD Portal
        </a>
      </li>
      <li>
        <a
          href="https://filecloud.mentiq.com/"
          target="_blank"
        >
          FileCloud
        </a>
      </li>
      <li>
        <a
          href="https://nagios.mentiq.com/"
          target="_blank"
        >
          Nagios
        </a>
      </li>
      <li>
        <a
          href="https://crm.mentiq.com/"
          target="_blank"
        >
          CRM
        </a>
      </li>
      <li>
        <a
          href="https://www.certmetrics.com/dell/login.aspx?ReturnUrl=%2fdell%2fdefault.aspx"
          target="_blank"
        >
          Cert Metrix
        </a>
      </li>
    </ul>

  </b-nav-item-dropdown>
</template>

<script>

import { mapGetters } from 'vuex'
import {
  BNavItemDropdown,
} from 'bootstrap-vue'

export default {
  components: {
    BNavItemDropdown,
  },
  computed: {
    ...mapGetters({
      isCurrentUserHost: 'auth/isHost',
    }),
  },
}
</script>
