<template>
  <div>
    <p class="clearfix mb-0">
      <span class="float-md-left d-block d-md-inline-block mt-25">
        COPYRIGHT  © {{ new Date().getFullYear() }}
        <b-link
          class="ml-25"
          href="https://www.mentiq.com"
          target="_blank"
        >mentIQ GmbH</b-link>
        <span class="d-none d-sm-inline-block">, All rights Reserved</span>
      </span>

      <span class="float-right">
        <span
          v-if="!isProduction"
          class="bg-warning text-light mr-2 p-1"
        >
          {{ envName }}
        </span>
        <span>v{{ version }}</span>
      </span>
    </p>
  </div>
</template>

<script>
import { BLink } from 'bootstrap-vue'
import appConfig from '@/config'

export default {
  components: {
    BLink,
  },
  data() {
    return {
      version: appConfig.version,
      isProduction: appConfig.isProduction(),
      envName: appConfig.environmentName,
    }
  },
}
</script>
