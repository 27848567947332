<template>
  <ul class="nav navbar-nav flex-row">

    <!-- Logo & Text -->
    <li class="nav-item mr-auto">
      <b-link
        class="navbar-brand"
        to="/"
      >
        <span class="brand-logo">
          <b-img
            :src="logoImage"
            alt="logo"
            :style="isVerticalMenuCollapsed ? 'width: 40px;' : 'width: 55px'"
          />
        </span>
        <!--
        <h2 class="brand-text">
          {{ appName }}
        </h2>
        -->
      </b-link>
    </li>

    <!-- Toggler Button -->
    <li class="nav-item nav-toggle">
      <b-link class="nav-link modern-nav-toggle">
        <feather-icon
          icon="XIcon"
          size="20"
          class="d-block d-xl-none"
          @click="toggleVerticalMenuActive"
        />
        <feather-icon
          :icon="collapseTogglerIconFeather"
          size="20"
          class="d-none d-xl-block collapse-toggle-icon"
          @click="toggleCollapsed"
        />
      </b-link>
    </li>
  </ul>
</template>

<script>
import { BLink, BImg } from 'bootstrap-vue'
import { $themeConfig } from '@themeConfig'
import { computed } from '@vue/composition-api'
import useVerticalNavMenu from '@core/layouts/layout-vertical/components/vertical-nav-menu/useVerticalNavMenu'
import useAppConfig from '@core/app-config/useAppConfig'

export default {
  components: {
    BLink,
    BImg,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      required: true,
    },
  },
  setup(props) {
    const { skin } = useAppConfig()

    const {
      collapseTogglerIcon,
      toggleCollapsed,
      isVerticalMenuCollapsed,
    } = useVerticalNavMenu(props)

    const collapseTogglerIconFeather = computed(() => (collapseTogglerIcon.value === 'unpinned' ? 'CircleIcon' : 'DiscIcon'))

    // App Name
    const {
      appLogoImage, appLogoImageDark, appLogoImageCollapsed, appLogoImageDarkCollapsed,
    } = $themeConfig.app

    const logoImage = computed(() => {
      if (skin.value === 'dark') {
        if (isVerticalMenuCollapsed) {
          return appLogoImageDarkCollapsed
        }

        return appLogoImageDark
      }

      if (isVerticalMenuCollapsed) {
        return appLogoImageCollapsed
      }

      return appLogoImage
    })

    return {
      collapseTogglerIconFeather,
      toggleCollapsed,
      isVerticalMenuCollapsed,
      logoImage,
    }
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/core/menu/menu-types/vertical-menu.scss";
</style>
